import Modal from "app/components/Modal";
import { buttonCss } from "app/components/Button";
import styled, { css } from "styled-components";

export const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 768px;
  height: 90vh;
  padding: 16px;
  margin: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const FullscreenModal = styled(Modal)`
  width: 100vw;
  height: 100vh;
  padding: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0;
  border-radius: 0;
`;

export const overrideButtonCss = css`
  ${buttonCss};
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 14px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 4px;
    padding: 2px;
  }

  .ais-SearchBox {
    height: 32px;
  }

  .ais-SearchBox-form {
    height: 100%;
    display: flex;
    gap: 16px;
  }

  .ais-SearchBox-input {
    border: none;
    background: ${({ theme }) => theme.colors.monochrome[1]};
    border-radius: 4px;
    max-width: 335px;
    width: 100%;
    padding-left: 36px;
    padding-right: 12px;
    :focus {
      outline: none;
    }
  }

  .ais-SearchBox-submit {
    ${overrideButtonCss};
  }

  .ais-SearchBox-reset {
    display: none;
  }
`;

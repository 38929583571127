import React from "react";
import { SearchBox } from "react-instantsearch-hooks-web";
import Icon, { Search } from "app/components/Icon";
import { SearchInputWrapper } from "./styles";

export function SearchInput() {
  return (
    <SearchInputWrapper>
      <SearchBox
        autoFocus
        searchAsYouType={false}
        submitIconComponent={({ classNames }) => (
          <div className={classNames.submitIcon}>Search</div>
        )}
        placeholder="Search"
      />
      <Icon as={Search} height="100%" />
    </SearchInputWrapper>
  );
}

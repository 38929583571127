import React from "react";
import { CloseModalButton } from "app/components/Modal";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { InstantSearch } from "react-instantsearch-hooks-web";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import { searchClient } from "app/components/Algolia/constants";
import { IS_MOBILE } from "constants/index";
import { StyledModal, FullscreenModal } from "./styles";
import { SearchResults } from "./SearchResults";
import { SearchInput } from "./SearchInput";

interface Props {
  closeModal(): void;
  playlistId: string;
}

export function AddClassesModal({ closeModal, playlistId }: Props) {
  const ModalComponent = IS_MOBILE ? FullscreenModal : StyledModal;
  return (
    <ModalComponent
      isOpen
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
    >
      <Flex justifyContent="space-between">
        <H2>Add Classes</H2>
        <CloseModalButton variant="light" onClick={closeModal} />
      </Flex>
      <InstantSearch
        indexName={AlgoliaIndexes.classes}
        searchClient={searchClient}
      >
        <Flex gap="16px" my="16px">
          <SearchInput />
        </Flex>
        <SearchResults playlistId={playlistId} />
      </InstantSearch>
    </ModalComponent>
  );
}

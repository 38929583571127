import styled from "styled-components";
import Button from "app/components/Button";
import Grid from "app/components/Grid";
import Flex from "app/components/Flex";
import { IS_MOBILE } from "constants/index";

export const NavFilters = styled(Flex)`
  ${IS_MOBILE ? "overflow: scroll" : "flex-wrap: wrap"};
  gap: 16px;
  margin-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    gap: 8px;
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

export const StyledButton = styled(Button)`
  border: 2px solid ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 24px;

  &:hover {
    background: ${({ theme }) => theme.colors.monochrome[1]};
  }
`;

export const CtaButton = styled(Button)`
  height: 32px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;
  gap: 8px;
  width: auto;
  padding: 10px;
`;

export const PlaylistListContainer = styled(Grid).attrs({
  gridGap: "16px",
  gridTemplateColumns: {
    _: "repeat(1, minmax(0, 1fr))",
    sm: "repeat(2, minmax(0, 1fr))",
    lg: "repeat(3, minmax(0, 1fr))",
  },
})``;

import {
  useSavePlaylistMutation,
  useUnsavePlaylistMutation,
  IsPlaylistSavedFragmentDoc,
  GetSavedPlaylistsDocument,
} from "services/graphql";
import { toast } from "react-toastify";
import { contentToggleSavePlaylist } from "services/typewriter/segment";
import { ANALYTICS_LOCATION, ANALYTICS_MODULE } from "constants/analytics";
import { useGetPlaylist } from "../hooks";

export function useSavePlaylist({
  playlistId,
  onCompleted,
  ...options
}: {
  playlistId: string;
  onCompleted?(): void;
}) {
  const { playlist } = useGetPlaylist({ playlistId });
  const [savePlaylist] = useSavePlaylistMutation({
    variables: {
      input: {
        playlistId,
      },
    },
    optimisticResponse: {
      playlistSave: true,
    },
    update(cache) {
      cache.writeFragment({
        id: `PlaylistV2:${playlistId}`,
        fragment: IsPlaylistSavedFragmentDoc,
        data: {
          isSaved: true,
        },
      });
    },
    onCompleted() {
      contentToggleSavePlaylist({
        saved: true,
        playlist_id: playlist.id,
        playlist_name: playlist.name,
        playlist_user_id: playlist.owner?.uid || "steezy",
        location: ANALYTICS_LOCATION.playlists,
        module: ANALYTICS_MODULE.playlist_details,
      });
      toast.success("Playlist saved to Followed Playlists");
      if (onCompleted) {
        onCompleted();
      }
    },
    refetchQueries: [
      {
        query: GetSavedPlaylistsDocument,
        variables: {
          pagination: { first: 10 },
        },
      },
    ],
    ...options,
  });

  return {
    savePlaylist,
  };
}

export function useUnsavePlaylist({
  playlistId,
  onCompleted,
  ...options
}: {
  playlistId: string;
  onCompleted?(): void;
}) {
  const { playlist } = useGetPlaylist({ playlistId });
  const [unsavePlaylist] = useUnsavePlaylistMutation({
    variables: {
      input: {
        playlistId,
      },
    },
    optimisticResponse: {
      playlistUnsave: true,
    },
    update(cache) {
      cache.writeFragment({
        id: `PlaylistV2:${playlistId}`,
        fragment: IsPlaylistSavedFragmentDoc,
        data: {
          isSaved: false,
        },
      });
    },
    onCompleted() {
      if (onCompleted) {
        onCompleted();
      }
      contentToggleSavePlaylist({
        saved: false,
        playlist_id: playlist.id,
        playlist_name: playlist.name,
        playlist_user_id: playlist.owner?.uid || "steezy",
        location: ANALYTICS_LOCATION.playlists,
        module: ANALYTICS_MODULE.playlist_details,
      });
      toast.success("Playlist removed from Followed Playlists");
    },
    refetchQueries: [
      {
        query: GetSavedPlaylistsDocument,
        variables: {
          pagination: { first: 10 },
        },
      },
    ],
    ...options,
  });

  return {
    unsavePlaylist,
  };
}
